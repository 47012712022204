<template>
	<ion-card :class="[
		'counter-card',
		(status === 'completed' || completed) ? 'counter-card--success' : '',
		status === 'warning' ? 'counter-card--warning' : ''
	]">
		<ion-card-header class="counter-card__title" color="transparent">
			<ion-card-title>{{ title }}</ion-card-title>
		</ion-card-header>
		<ion-card-content class="counter-card__content">
			<ion-text class="counter-card__amount-required">
				<span class="counter-card__amount">{{ amount }}{{ amountLabel }}</span>
				<template v-if="requiredAmount">
					<span class="counter-card__required">&nbsp;/ {{ requiredAmount }}{{ amountLabel }}</span>
				</template>
			</ion-text>
			<ion-icon class="counter-card__amount-icon" :src="icon" size="large"></ion-icon>
		</ion-card-content>
		<ion-card-content class="counter-card__icon-message" v-if="message && message.text">
			<ion-icon :src="message.icon" size="large" v-if="message.icon"></ion-icon>
			<p>{{ message.text }}</p>
		</ion-card-content>
		<ion-card-content class="ion-no-padding" v-if="linkButton && linkButton.path && linkButton.text && !completed">
			<ion-button
				class="counter-card__action-btn"
				@click="$router.push(linkButton.path)"
				:color="linkButton.color"
				:disabled="!isConnectedToInternet"
				expand="block"
			>{{ linkButton.text }}</ion-button>
		</ion-card-content>
		<ion-card-content class="counter-card__info-text" v-if="infoText">
			{{ infoText }}
		</ion-card-content>
	</ion-card>
</template>

<script>
import {
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonIcon
} from '@ionic/vue';
import {
	medal
} from 'ionicons/icons';
import { useStore } from "vuex";

export default {
	name: 'Counter Card',
	props: {
		amount: {
			default: '',
			type: String
		},
		amountLabel: {
			default: '',
			type: String
		},
		completed: {
			default: false,
			type: Boolean
		},
		icon: {
			type: String
		},
		linkButton: {
			default: {},
			type: Object
		},
		message: {
			default: {},
			type: Object
		},
		infoText: {
			default: '',
			type: String
		},
		requiredAmount: {
			default: '',
			type: String
		},
		status: {
			default: '',
			type: String
		},
		title: {
			type: String
		}
	},
	components: {
		IonButton,
		IonCard,
		IonCardContent,
		IonCardHeader,
		IonCardTitle,
		IonIcon
	},
	computed: {
		isConnectedToInternet() {
			return this.store?.getters?.connectionStatus?.connected;
		}
	},
	setup() {
		const store = useStore();

		return {
			medal,
			store
		};
	}
}
</script>

<style lang="scss" scoped>

.counter-card {
	box-shadow: var(--box-shadow);
	color: var(--ion-text-color);
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-between;
	margin: 0;
	padding: 15px;
	height: 100%;

	&__title {
		margin-bottom: 0;
		order: 2;
		padding: 0;
		text-align: left;
	}

		ion-card-title {
			font-size: 1em;
			font-weight: 700;
			padding: 0 30px 0 0;
		}

	&__content {
		display: flex;
		justify-content: space-between;
		margin-bottom: 0;
		order: 3;
		padding: 0;
		position: relative;
	}

		&__amount-required {
			align-items: flex-end;
			display: flex;
			font-size: 1.75em;
			font-weight: 700;
		}
			&__amount-label {
				font-size: 1em;
				line-height: 1.5em;
			}

		&__amount-icon {
			color: rgba( var(--ion-color-dark-rgb), 0.75);
		}

		&__action-btn {
			padding: 0;
			margin-top: 10px;
		}

		&__icon-message {
			align-items: center;
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			border-radius: 6px;
			column-gap: 15px;
			display: flex;
			margin-top: 15px;
			padding: 10px;

			ion-icon {
				flex: 0 0 30px;
				height: 30px;
				margin: 0;
				width: 30px;
			}

			p {
				font-size: 16px;
				font-weight: 700;
				text-align: left;
			}
		}

		&__info-text {
			background-color: var(--ion-color-tertiary-lighten-9);
			border-color: var(--ion-color-tertiary);
			border-radius: 6px;
			border-style: dashed;
			border-width: 2px;
			color: var(--ion-color-tertiary);
			font-size: 16px;
			font-weight: bold;
			margin-top: 15px;
			padding: 7px 10px;
			text-align: left;
		}

	&--success {
		.counter-card__icon-message {
			background-color: var(--ion-color-success);
		}
	}

	&--warning {
		.counter-card__icon-message {
			background-color: var(--ion-color-warning);
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			border-radius: 6px;
			padding: 10px;
		}
	}

		&--success,
		&--warning {

			.counter-card__icon-message p {
				line-height: 1.35em;
			}

			.counter-card {

				&__icon-message {
					color: #FFFFFF
				}
			}
		}
}
</style>