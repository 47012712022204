<template>
	<ion-header id="app-toolbar">
		<ion-toolbar class="header-toolbar">
			<ion-buttons slot="start" v-if="backButton || customBackButton">
				<ion-button @click="(customBackButton) ? ionRouter.navigate(customBackButton, 'back', 'replace') : $router.go(-1)">
					<ion-icon :ios="chevronBackOutline" :md="chevronBackOutline"></ion-icon>
				</ion-button>
			</ion-buttons>

			<AppLogo driveTrakLogoColor="white" />

			<ion-select
				:interface-options="{ header: 'Student' }"
				@ionChange="switchUser"
				slot="end"
				:value="store.getters.selectedUser"
				class="header-student-name"
				v-if="!loading && store.getters.students.length > 1 && store.state.auth.user.isParent"
			>
				<ion-select-option
					:key="student.userID"
					:value="student.userID"
					v-for="student in store.getters.students"
				>
					{{student.firstName}} {{student.lastName}}
				</ion-select-option>
			</ion-select>
			<template v-else-if="store.state.auth.user.isParent">
				<p slot="end" class="header-text" :key="student.userID" v-for="student in store.getters.students">{{student.firstName}} {{student.lastName}}</p>
			</template>
			<ion-buttons slot="end" class="header-help-settings-buttons">
				<ion-button
					v-if="!isConnectedToInternet"
					fill="solid"
					color="danger"
					class="tool-bar-button"
					@click="showConnectionModal()"
				>
					<MaterialDesignIcon color="light" class="wifi-alert-icon" icon="wifi-alert" />

				</ion-button>
				<ion-button @click="showHelpModal()" class="tool-bar-button">
					<ion-icon
						:ios="helpCircleOutline"
						:md="helpCircleOutline"
						style="height: 25px; width: 25px;"
					></ion-icon>
				</ion-button>
				<ion-button
					@click="showSettingsModal()"
					class="tool-bar-button"
					:disabled="!isConnectedToInternet"
				>
					<ion-icon
						:ios="settings"
						:md="settings"
					></ion-icon>
				</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>
</template>

<script>
import AppLogo from "@/view/components/graphics/AppLogo.vue";
import { chevronBackOutline, helpCircleOutline, logOut } from "ionicons/icons";
import MaterialDesignIcon from '@/view/components/MaterialDesignIcon.vue';
import { settings } from "ionicons/icons";
import { defineComponent, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { useIonRouter } from '@ionic/vue';
import { Network } from '@capacitor/network';

export default defineComponent({
	data() {
		return {
			loading: false,
			connectionStatus: {
				connected: true
			},
		}
	},
	name: "AppHeader",
	components: {
		AppLogo,
		MaterialDesignIcon,
	},
	props: {
		backButton: {
			type: Boolean
		},
		customBackButton: {
			default: '',
			type: String
		},
		menu: {
			type: Boolean
		},
	},
	setup() {
		const isOpenRef = ref(false);
		const route = useRoute();
		const ionRouter = useIonRouter();

		return {
			chevronBackOutline,
			helpCircleOutline,
			ionRouter,
			isOpenRef,
			settings,
			route,
			logOutIcon: logOut,
			store: useStore(),
			router: useRouter(),
		};
	},
	async ionViewWillEnter() {
		this.loading = true;
		this.loading = false;
	},
	created() {
		// initial connection status
		this.updateConnectionStatus();

		// listener for connection changes
		Network.addListener('networkStatusChange', this.updateConnectionStatus);

		// connection polling every 30 seconds in case the listener lands on a bad state
		setInterval(() => {
			this.updateConnectionStatus();
		}, 30000);
	},
	methods: {
		showHelpModal() {
			this.store.commit('showHelpModal', '');
		},
		showConnectionModal() {
			this.store.commit('showConnectionModal');
		},
		showSettingsModal() {
			this.store.commit('showSettingsModal');
		},
		async switchUser(ev) {
			this.$emit('studentChange', ev.detail.value);
			this.store.dispatch('switchSelectedUser', ev.detail.value);
		},
		async updateConnectionStatus(incomingStatus) {
			if (incomingStatus) {
				this.connectionStatus = incomingStatus;
				return;
			}
			const status = await Network.getStatus();
			this.connectionStatus = status;
		},
	},
	computed: {
		institutionSettings() {
			return this.store.getters.institutionSettings;
		},
		isConnectedToInternet() {
			return this.connectionStatus?.connected;
		}
	}
});
</script>

<style lang="scss">
#app-toolbar {

	.header-toolbar {
		--background: var(--header-background, transparent);
		--color: var(--header-background-contrast, '#333333');

		.app-logo {
			align-items: center;
			display: flex;
			max-height: 50px;
			max-width: 125px;
			margin-left: 15px;

			.header-logo {
				max-height: 50px;
			}

			.drivetrak-logo svg {
				max-height: 30px;
				max-width: 129px;
			}
		}
	}
}

.header-text {
	--color: var(--ion-color-primary-contrast, #FFFFFF);
}

.wifi-alert-icon {
	height: 25px;
	width: 25px;
	color: #FFFFFF;
}

.header-help-settings-buttons {
	height: 56px !important;
	align-items: stretch !important;
	padding: 0 10px !important;
	margin: 0 !important;
}

.tool-bar-button {
	--border-radius: 0 !important;
	height: 56px !important;
	width: 43px !important;
}

.header-toolbar {
	--min-height: 56px;
	--padding-top: 0;
	--padding-bottom: 0;
	--border-width: 0 !important;
}

ion-toolbar {
	--background: var(--ion-color-primary);
	border: 0;
}

ion-select.header-student-name::part(text) {
	--color: var(--ion-color-primary-contrast);
}

ion-select.header-student-name::part(icon) {
	--color: var(--ion-color-primary-contrast);
	opacity: 1;
}
</style>