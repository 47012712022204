<template>
	<ion-page>
		<ion-header id="app-toolbar">
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-button @click="hideSettingsModal()" color="grey">
						<ion-icon color="light" :md="arrowBack" :ios="arrowBack"></ion-icon>
					</ion-button>
				</ion-buttons>
				<ion-title color="light">Profile</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<ion-list>
				<ion-item v-if="showNavItem('APPOINTMENT_HISTORY')" button @click="navigateTo('appointment-history')">
					<ion-icon :ios="listOutline" :md="listOutline" slot="start"></ion-icon>
					<ion-label>
						<h3>Appointment History</h3>
					</ion-label>
				</ion-item>
				<template v-if="showNavItem('PERMIT_DETAILS')">
					<ion-item v-for="(student, index) in store.state.students" :key="index" button @click="goTo('user-details', student.userID)">
						<ion-icon :ios="informationCircleOutline" :md="informationCircleOutline" slot="start"></ion-icon>
						<ion-label>
							<h3>Permit Details
								<template v-if="store.state.auth.user.isParent">- {{student.firstName}} {{student.lastName}}</template>
							</h3>
						</ion-label>
					</ion-item>
				</template>
				<!-- behind feature flag -->
				<template v-if="showNavItem('BILLING')">
					<ion-item v-for="(student, index) in store.state.students" :key="index" button @click="goTo('billing', student.userID, student.institution)">
						<ion-icon :ios="cardOutline" :md="cardOutline" slot="start"></ion-icon>
						<ion-label>
							<h3>Billing
								<template v-if="store.state.auth.user.isParent">- {{student.firstName}} {{student.lastName}}</template>
							</h3>
						</ion-label>
					</ion-item>
				</template>
				<template v-if="showNavItem('PREFERENCES')">
					<ion-item v-for="(student, index) in store.state.students" :key="index" button @click="goTo('preferences', student.userID, student.institution)">
						<ion-icon :icon="buildOutline" slot="start"></ion-icon>
						<ion-label>
							<h3>Preferences
								<template v-if="store.state.auth.user.isParent">- {{student.firstName}} {{student.lastName}}</template>
							</h3>
						</ion-label>
					</ion-item>
				</template>
				<!-- <ion-item button @click="goTo('appearance')">
					<ion-icon :ios="contrast" :md="contrast" slot="start"></ion-icon>
					<ion-label>
						<h3>Appearance</h3>
					</ion-label>
				</ion-item> -->
				<ion-item detail="false">
					<ion-icon :ios="constructOutline" :md="constructOutline" slot="start"></ion-icon>
					<ion-label>
						<h3>Version</h3>
					</ion-label>
					<span slot="end"><span class="platform-details" v-if="appPlatform">{{appPlatform}}</span>{{appVersion}}</span>
				</ion-item>
				<ion-item button @click="logOutUser()" detail="false">
					<ion-icon :ios="personCircleOutline" :md="personCircleOutline" slot="start"></ion-icon>
					<ion-label>
						<h3>{{ store.getters.user.firstName }} {{ store.getters.user.lastName }}</h3>
					</ion-label>
					<ion-button
						color="danger"
						fill="outline"
						size="small"
						slot="end"
					>
						LOGOUT
					</ion-button>
				</ion-item>
			</ion-list>
		</ion-content>
	</ion-page>
</template>

<script>
import { defineComponent, onMounted, ref} from "vue";
import {
	IonChip,
	IonContent,
	IonIcon,
	IonTitle,
	IonToolbar
} from '@ionic/vue';
import {
	arrowBack,
	cardOutline,
	calendarOutline,
	chevronForward,
	close,
	constructOutline,
	contrast,
	informationCircleOutline,
	listOutline,
	logOut,
	person,
	personCircleOutline,
	buildOutline
} from 'ionicons/icons';
import settingsAppearance from './SettingsAppearance.vue';
import settingsAppointmentHistory from './SettingsAppointmentHistory.vue';
import settingsUserDetails from './SettingsUserDetails.vue';
import settingsBilling from "./SettingsBilling.vue";
import settingsPreferences from "./SettingsPreferences.vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import envConfig from "@/core/config/env.config";
import { api } from "@/core/api/api";

export default defineComponent({
	components: {
		IonChip,
		IonContent,
		IonIcon,
		IonTitle,
		IonToolbar
	},
	data() {
		return {
			user: {
				students: []
			},
			appVersion: envConfig.version,
			appPlatform: (envConfig.platform === 'ios-ionic') ? 'ios' : (envConfig.platform === 'android-ionic') ? 'android' : envConfig.platform
		}
	},
	mounted() {
		if (this.store.state.showSettingsData?.type === 'userDetails') {
			this.navigateTo('user-details', this.store.state.showSettingsData.user);
		}
	},
	methods: {
		async logOutUser() {
			this.hideSettingsModal();
			this.$nextTick(async () => {
				this.logData('logout');
				await this.store.dispatch('logOut');
				await this.router.replace('/login');
			});
		},
		hideSettingsModal() {
			this.store.commit('hideSettingsModal');
		},
		goTo(page, userID, institutionSlug){
			this.logData(page);
			this.navigateTo(page, userID, institutionSlug)
		},
		/**
		 * Determine if the nav item should be shown
		 * @param {string} navType
		 * @returns {boolean}
		 * @example
		 * showNavItem('BILLING')
		 */
		showNavItem(navType) {
			switch(navType) {
				case 'BILLING':
					return this.store.getters.students.length &&
						this.store.getters.featureFlags?.inAppBilling &&
						this.store.getters.institutionSettings?.moduleSettings?.registration;
				case 'PERMIT_DETAILS':
					return this.store.getters.students.length &&
						this.store.getters.institutionSettings?.moduleSettings?.registration;
				case 'APPOINTMENT_HISTORY':
					return this.store.getters.students.length &&
						this.store.getters.institutionSettings?.moduleSettings?.schedulerAccess;
				case 'PREFERENCES':
					return this.store.getters.students.length &&
						this.store.getters.institutionSettings?.schedulerSettings?.preferredPickupLocations?.enabled;
				default:
					return true;
			}
		},
		logData(page){
			let event = '';
			let tags = [] ;
			switch(page) {
				case 'appearance':
					event = 'View Appearance'
					tags.push('Navigation')
					break;
				case 'appointment-history':
					event = 'View Appointment History'
					tags.push('Navigation')
					break;
				case 'user-details':
					event = 'View Permit Details'
					tags.push('Navigation')
					break;
				case 'billing':
					event = 'View Billing'
					tags.push('Navigation')
					break;
				case 'preferences':
					event = 'Preferences'
					tags.push('Navigation')
					break;
				case 'logout':
					event = 'Logout'
					tags.push('Authentication')
					break;
			}

			const logData = {
				institution: this.store.getters.institutionSettings.slug,
				event: event,
				affectedUserID: this.store.getters.user.userID,
				additionalData:{
					email: this.store.getters.user.email,
					activeUser: this.store.getters.selectedUser,
					currentAppVersions: this.store.getters.featureFlags.appVersions,
					mobileAppVersion: envConfig.version,
					tags: tags,
					status: 'Success'
				}
			};
			api.logMobileActivity(logData);
		}
	},
	name: "SettingsNav",
	setup() {
		const route = useRoute();
		const router = useRouter();
		const settingsIonNav = document.querySelector('ion-nav');
		const store = useStore();
		const userID = store.getters.user.userID;
		const modalNav = ref(null);

		onMounted(() => {
			const v = document.getElementById('settings-ion-nav');
			modalNav.value = v;
		});

		const navigateTo = (page, studentUserID, institution) => {
			switch(page) {
				case 'appearance':
					modalNav.value.push(settingsAppearance, {
						modalNav: modalNav
					});
					break;
				case 'appointment-history':
					modalNav.value.push(settingsAppointmentHistory, {
						modalNav: modalNav
					});
					break;
				case 'user-details':
					modalNav.value.push(settingsUserDetails, {
						modalNav: modalNav,
						userID: studentUserID
					});
					break;
				case 'billing':
					modalNav.value.push(settingsBilling, {
						modalNav: modalNav,
						userID: studentUserID,
						institution
					});
					break;
				case 'preferences':
					modalNav.value.push(settingsPreferences, {
						modalNav: modalNav,
						userID: studentUserID,
						institution
					});
					break;
			}
		};

		return {
			arrowBack,
			buildOutline,
			calendarOutline,
			cardOutline,
			chevronForward,
			close,
			constructOutline,
			contrast,
			informationCircleOutline,
			listOutline,
			logOut,
			navigateTo,
			person,
			personCircleOutline,
			route,
			router,
			settingsIonNav,
			store,
			userID
		};
	}
});
</script>

<style lang="scss" scoped>
ion-content,
ion-item,
ion-header {
	ion-toolbar {
		background-color: var(--ion-color-primary);
		border: 0;
	}

}

ion-toolbar {
	--background: var(--ion-color-primary);
	border: none;
	--color: #FFFFFF;
}

.platform-details {
	font-size: 12px;
	font-weight: 700;
	margin-right: 5px;
}
</style>