<template>
	<ion-modal>
		<ion-header>
			<ion-toolbar>
				<ion-title color="light">Filter Calendar</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="$emit('didDismiss')">
						<ion-icon color="light" :icon="closeOutline" />
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content fullscreen>
			<ion-item-group>
				<ion-item
					button
					@click="openInstructorSelect"
					detail="false"
					:hidden = "filterRestrictions.hideInstructors"
				>
					<ion-label position="floating">Instructor</ion-label>
					<ion-select
						:value="selectedInstructor"
						ref="instructorSelect"
						:compareWith="(left, right) => left && right && left.userID === right.userID"
						@ionChange="selectInstructor"
						@ionFocus="instructorSelectOpened = true"
						@ionBlur="instructorSelectOpened = false"
					>
						<ion-select-option
							v-for="instructor in instructors"
							:key="instructor?.userID"
							:value="instructor"
						>{{instructor.firstName}} {{instructor.lastName}}</ion-select-option>
					</ion-select>
					<ion-icon @click.stop="clearInstructor" :icon="closeOutline" slot="end" v-show="selectedInstructor" />
				</ion-item>

				<ion-item
					button
					@click="openLocationSelect"
					detail="false"
				>
					<ion-label position="floating">Location</ion-label>
					<ion-select
						:value="selectedLocation"
						ref="locationSelect"
						:compareWith="(left, right) => left && right && `${left.name} ${left.address}` === `${right.name} ${right.address}`"
						@ionChange="selectLocation"
						@ionFocus="locationSelectOpened = true"
						@ionBlur="locationSelectOpened = false"
					>
						<ion-select-option v-for="location in locations" :key="location.name + location.address" :value="location">
							{{location.name}} - {{location.address}}
						</ion-select-option>
					</ion-select>
					<ion-icon @click.stop="clearLocation" :icon="closeOutline" slot="end" v-show="selectedLocation" />
				</ion-item>

				<ion-item
					button
					@click="openSchoolSelect"
					detail="false"
					v-if="schools.length > 0"
				>
					<ion-label position="floating">School</ion-label>
					<ion-select
						:value="(filters?.school) ? filters.school : selectedSchool"
						ref="schoolSelect"
						:compareWith="(left, right) => left && right && `${left.name} ${left.address}` === `${right.name} ${right.address}`"
						@ionChange="selectSchool"
						@ionFocus="schoolSelectOpened = true"
						@ionBlur="schoolSelectOpened = false"
						:disabled="filterRestrictions.lockSchool"
					>
						<ion-select-option v-for="school in schools" :key="school" :value="school">
							{{school.name}}
						</ion-select-option>
					</ion-select>
					<ion-icon @click.stop="clearSchool" :icon="closeOutline" slot="end" v-show="(selectedSchool && !filterRestrictions.lockSchool)" />
				</ion-item>

				<ion-item
					button
					@click="openZoneSelect"
					detail="false"
					v-if="zones.length > 0"
				>
					<ion-label position="floating">Zone</ion-label>
					<ion-select
						multiple="true"
						:value="(filters?.zones) ? filters.zones : selectedZones"
						ref="zoneSelect"
						:compareWith="(left, right) => left && right && `${left.name} ${left.address}` === `${right.name} ${right.address}`"
						@ionChange="selectZone"
						@ionFocus="zoneSelectOpened = true"
						@ionBlur="zoneSelectOpened = false"
						:disabled="filterRestrictions.lockZones"
					>
						<ion-select-option v-for="zone in zones" :key="zone" :value="zone">
							{{zone.name}}
						</ion-select-option>
					</ion-select>
					<ion-icon @click.stop="clearZone" :icon="closeOutline" slot="end" v-show="(selectedZones && !filterRestrictions.lockZones)" />
				</ion-item>

				<ion-item
					button
					@click="openSessionTypeSelect"
					detail="false"
					v-if="!store.getters.observeDisabled"
				>
					<ion-label position="floating">Type</ion-label>
					<ion-select
						:value="selectedSessionType || ''"
						ref="sessionTypeSelect"
						@ionChange="selectSessionType"
						@ionFocus="sessionTypeSelectOpened = true"
						@ionBlur="sessionTypeSelectOpened = false"
					>
						<ion-select-option value="">
							Drive or Observe
						</ion-select-option>
						<ion-select-option v-for="type in SessionType" :key="type" :value="type">
							{{uppercaseFirstLetter(type)}}
						</ion-select-option>
					</ion-select>
					<ion-icon @click.stop="clearSessionType" :icon="closeOutline" slot="end" v-show="selectedSessionType" />
				</ion-item>

				<ion-item>
					<ion-label>No other students scheduled yet</ion-label>
					<ion-toggle
						color="success"
						v-model="noOtherStudents"
						checked="false"
						@click.stop
					/>
				</ion-item>

			</ion-item-group>

			<span class="ion-margin-bottom ion-margin-top wedge"/>

			<div class="ion-padding">
				<ion-button
					class="ion-margin-bottom"
					color="primary"
					expand="block"
					size="default"
					fill="solid"
					@click="applyFilters"
				>Apply Filters</ion-button>

				<ion-button
					@click="resetFilters"
					color="primary"
					expand="block"
					fill="clear"
					size="default"
				>Reset All</ion-button>
			</div>

		</ion-content>
	</ion-modal>
</template>

<script lang="ts">
import {
	IonButton,
	IonModal
} from '@ionic/vue';
import { useStore } from 'vuex';
import { defineComponent, PropType, ref } from "vue";
import {
  closeOutline
} from 'ionicons/icons';
import { SessionType } from '@/core/SessionType';
import { ContactInfo, Location, School, User, Zone } from '@/core/store/store';

export default defineComponent({
	components: {
		IonButton,
		IonModal
	},
	props: {
		filters: {
			type: Object,
			required: true
		},
		instructors: {
			type: Array as PropType<ContactInfo[]>,
			required: true
		},
		locations: {
			type: Array as PropType<Location[]>,
			required: true
		},
		schools: {
			type: Array as PropType<School[]>,
			required: true
		},
		zones: {
			type: Array as PropType<Zone[]>,
			required: true
		},
		filterRestrictions: {
			type: Object,
			required: true
		}
	},
	setup() {
		const store = useStore();

		const instructorSelect = ref(null);
		const locationSelect = ref(null);
		const schoolSelect = ref(null);
		const zoneSelect = ref(null);

		return {
			closeOutline,
			instructorSelect,
			locationSelect,
			schoolSelect,
			zoneSelect,
			SessionType,
			store,
		};
	},
	data() {
		return {
			selectedInstructor: null,
			selectedLocation: null,
			selectedSchool: null,
			selectedZones: null,
			noOtherStudents: null,
			selectedSessionType: null,

			// These flags are needed so that when the selects are opened programmatically,
			// they are not double opened
			sessionTypeSelectOpened: false,
			instructorSelectOpened: false,
			locationSelectOpened: false,
			schoolSelectOpened: false,
			zoneSelectOpened: false,

		};
	},
	methods: {
		applyFilters() {
			this.$emit('apply-filters', {
				instructor: this.selectedInstructor,
				location: this.selectedLocation,
				school: this.selectedSchool,
				zones: this.selectedZones,
				sessionType: this.selectedSessionType,
				noOtherStudents: this.noOtherStudents
			});
		},
		resetFilters() {
			this.$emit('reset-filters')
		},
		selectInstructor({ detail }) {
			// This will be an empty string when the input is cleared.  We don't
			// want this value to ever be a string, so we should return early.
			if (!detail.value) return;

			this.selectedInstructor = detail.value;
		},
		clearInstructor() {
			this.selectedInstructor = null;
		},
		selectLocation({ detail }) {
			// This will be an empty string when the input is cleared.  We don't
			// want this value to ever be a string, so we should return early.
			if (!detail.value) return;

			this.selectedLocation = detail.value;
		},
		clearLocation() {
			this.selectedLocation = null;
		},
		selectSchool({ detail }) {
			// This will be an empty string when the input is cleared.  We don't
			// want this value to ever be a string, so we should return early.
			if (!detail.value) return;

			this.selectedSchool = detail.value;
		},
		clearSchool() {
			this.selectedSchool = null;
		},
		selectZone({ detail }) {
			// This will be an empty string when the input is cleared.  We don't
			// want this value to ever be a string, so we should return early.
			if (!detail.value) return;

			this.selectedZones = detail.value;
		},
		clearZone() {
			this.selectedZones = null;
		},
		selectSessionType({ detail }) {
			// This will be an empty string when the input is cleared.  We don't
			// want this value to ever be a string, so we should return early.
			if (!detail.value) return;

			this.selectedSessionType = detail.value;
		},
		clearSessionType() {
			this.selectedSessionType = null;
		},
		openInstructorSelect(event) {
			if (this.instructorSelectOpened) return;

			this.$refs.instructorSelect.$el.open(event);
		},
		openLocationSelect(event) {
			if (this.locationSelectOpened) return;

			this.$refs.locationSelect.$el.open(event);
		},
		openSchoolSelect(event) {
			if (this.schoolSelectOpened) return;

			this.$refs.schoolSelect.$el.open(event);
		},
		openZoneSelect(event) {
			if (this.zoneSelectOpened) return;

			this.$refs.zoneSelect.$el.open(event);
		},
		openSessionTypeSelect(event) {
			if (this.sessionTypeSelectOpened) return;

			this.$refs.sessionTypeSelect.$el.open(event);
		},
		uppercaseFirstLetter(str: string) {
			return str.replace(/^[a-z]/, (character) => character.toUpperCase());
		}
	}
});
</script>

<style scoped lang="scss">

ion-item {
	ion-icon {
		margin-top:16px;
	}
}

.date-modal {
	background-color: rgba(0, 0, 0, 0.3);
	--border-radius: 8px;
	--height: 80%;
	--width: 80%;
	--max-width: 350px;

	ion-datetime {
		height: 100%;
		max-width: 350px;
		width: auto;
	}
}

ion-content,
ion-item,
ion-header {
	ion-toolbar {
		background-color: var(--ion-color-primary);
		border: 0;
	}

}

</style>