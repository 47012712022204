<template>
	<ion-page id="main-content">
		<app-header customBackButton="/lessons" />
		<lesson-progress-bar
			:inline="true"
			:lesson="lesson"
			:lessonCompleted="lessonProgress.completeDate ? true : false"
			:topicsCompleted="topicsCompleted"
			:resourcesCompleted="resourcesCompleted"
			@menuToggle="(value) => {lessonProgressBar = value;}"
		/>
		<ion-content class="ion-padding-vertical" fullscreen scrollY="true" v-if="lesson">
			<resource
				v-bind:key="resource.resourceID"
				:allowVideoPlay="!lessonProgressBar"
				:resource="resource"
				v-for="resource in currentResourceGroup"
				v-if="currentLessonTopic"
				:lessonID="routeLessonID"
				:topicID="routeTopicID"
			></resource>
			<div :class="['lesson-nav-buttons', (isFirstResourceGroup && isFirstTopic) ? 'lesson-nav-buttons--align-right' : '']">
				<ion-button
					class="ion-margin-bottom"
					@click="previousBtn()"
					color="primary"
					:disabled="!isConnectedToInternet"
					v-if="!isFirstTopic || previousResourceID"
				>
					<ion-icon slot="start" :icon="arrowBack"></ion-icon>
					Previous
				</ion-button>
				<ion-button
					class="ion-margin-bottom"
					@click="nextBtn()"
					color="success"
					:disabled="!currentTopicResourcesCompleted || loading || !isConnectedToInternet"
				>
					<ion-spinner v-if="loading"/>
					<template v-else>
						{{ continueData.label }}
						<ion-icon slot="end" :icon="arrowForward"></ion-icon>
					</template>
				</ion-button>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import AppHeader from '@/view/components/AppHeader.vue';
import LessonProgressBar from '@/view/components/LessonProgressBar.vue';
import Resource from '@/view/components/Resource.vue';
import {
	IonButton,
	IonIcon,
	IonToolbar,
	useIonRouter
} from '@ionic/vue';
import { computed, defineComponent, ref } from "vue";
import {
	arrowBack,
	arrowForward,
	caretDownOutline,
	clipboard,
	informationCircle,
	trophy
} from 'ionicons/icons';
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { api, DailyLimitMetError } from '@/core/api/api';
import envConfig from '@/core/config/env.config';

export default defineComponent({
	name: "lesson",
	components: {
		AppHeader,
		IonButton,
		IonIcon,
		IonToolbar,
		LessonProgressBar,
		Resource
	},
	computed: {
		classroomUserProgress() {
			return this.classroomUserProgressState.classroomUserProgress ?? null;
		},
		classroomUserProgressState() {
			return this.store.getters.studentsClassroomProgress.find(scp => scp.userID === this.store.getters.selectedUser) ?? {};
		},
		continueData() {
			// If there is another remaining resource move to the next resource.
			if (this.nextResourceID) {
				return {
					url: `/lessons/${this.routeLessonID}/${this.routeTopicID}/${this.nextResourceID}`,
					label: 'Continue'
				};
			}

			// If there is another remaining topic move to a quiz or the next topic.
			if (this.currentLessonTopic.hasQuiz && this.$store.getters.getQuizTestAttempts({topicID: this.routeTopicID, lessonID: this.routeLessonID}) < this.syllabus.quizSettings?.attemptsAllowed) {
				return {
					url: `/quiztest/${this.routeLessonID}/${this.routeTopicID}`,
					label: 'Quiz'
				};
			} else {
				if (this.nextTopicID) {
					return {
						url: `/lessons/${this.routeLessonID}/${this.nextTopicID}`,
						label: 'Next Topic'
					};
				} else {
					if (this.lesson.hasTest && this.$store.getters.getQuizTestAttempts({lessonID: this.routeLessonID}) < this.syllabus.testSettings?.attemptsAllowed) {
						return {
							url: `/quiztest/${this.routeLessonID}`,
							label: 'Lesson Test'
						};
					} else {
						return {
							url: `/summary/${this.routeLessonID}`,
							label: 'Finish Lesson'
						};
					}
				}
			}
		},
		currentLessonTopic() {
			if (!this.lesson.topics) { return {}; }

			let currentTopic = false;
			if (this.routeTopicID) {
				currentTopic = this.lesson.topics.find(t => t.topicID === this.routeTopicID);
			} else {
				currentTopic = this.lesson.topics[0];
			}

			return currentTopic;
		},
		currentTopicResourcesCompleted() {
			if (!this.topicResourcesCompleted || !this.currentLessonTopic) { return false; }

			return this.currentResourceGroup.every(({resourceID, content}) => !!this.topicResourcesCompleted.find(({reference}) => reference === resourceID) || content.type === 'BREAK');
		},
		isConnectedToInternet() {
			return this.store?.getters?.connectionStatus?.connected;
		},
		isFirstResourceGroup() {
			return !this.previousResourceID;
		},
		isFirstTopic() {
			if (!this.lesson.topics) { return false; }
			if (!this.routeTopicID || this.routeTopicID === 'undefined') { return true; }
			const indexID = this.lesson.topics.findIndex(t => t.topicID === this.routeTopicID);
			return (indexID === 0) ? true : false;
		},
		lesson() {
			if (!this.lessons) { return {}; }
			return this.lessons.find(l => l.lessonID === this.routeLessonID);
		},
		lessonProgress() {
			return this.classroomUserProgress?.lessons?.find((l) => l.lessonID === this.routeLessonID) ?? {};
		},
		lessonResourcesCompleted() {
			if (!this.classroomUserProgress || !this.classroomUserProgress.lessons) { return []; }
			const currentLessonProgress = this.classroomUserProgress.lessons.find(o => o.lessonID === this.routeLessonID);

			// If we don't find the current lesson return an empty array.
			if (!currentLessonProgress) { return []; }

			// Otherwise return a list of topics completed.
			return currentLessonProgress.progressItems.filter(item => item.type === 'RESOURCES');
		},
		topicResourcesCompleted() {
			if (!this.classroomUserProgress || !this.classroomUserProgress.lessons) { return []; }
			const currentLessonProgress = this.classroomUserProgress.lessons.find(o => o.lessonID === this.routeLessonID);

			// If we don't find the current lesson return an empty array.
			if (!currentLessonProgress) { return []; }

			// Otherwise return a list of resources completed.
			return currentLessonProgress.progressItems.filter(item => item.type === 'RESOURCE');
		},
		previousResourceID() {
			if (!this.currentResourceGroup.length) return '';

			const lastResourceGroupIndexEnd = this.currentLessonTopic.resources.findIndex(r => r.resourceID === this.currentResourceGroup[0]?.resourceID) - 1;
			let lastResourceGroupIndexStart = this.currentLessonTopic.resources.findLastIndex((r, index) => {
				return r.resourceID !== this.currentResource.resourceID && index < lastResourceGroupIndexEnd && r.content.type === 'BREAK';
			});
			const resourceAfterBreakIndex = lastResourceGroupIndexStart + 1;

			if (this.currentResource.resourceID === this.currentLessonTopic.resources[resourceAfterBreakIndex]?.resourceID) {
				return '';
			}

			return this.currentLessonTopic.resources[resourceAfterBreakIndex]?.resourceID || '';
		},
		currentResource() {
			if (!this.currentLessonTopic.resources) return {};

			if (this.routeResourceID) {
				return this.currentLessonTopic.resources.find(r => r.resourceID === this.routeResourceID) ?? {};
			}

			return this.currentLessonTopic.resources.find(r => r.content.type !== 'BREAK') ?? {};
		},
		currentResourceGroup() {
			if (!this.currentResource.resourceID) return [];

			const currentResourceIndex = this.currentLessonTopic.resources.findIndex(r => r.resourceID === this.currentResource.resourceID);
			let nextBreakIndex = this.currentLessonTopic.resources.findIndex((r, index) => index > currentResourceIndex && r.content.type === 'BREAK');
			let endOfGroupIndex;
			if (nextBreakIndex === -1) {
				endOfGroupIndex = this.currentLessonTopic.resources.length;
			} else {
				// Include the break in the group
				endOfGroupIndex = nextBreakIndex + 1;
			}

			return this.currentLessonTopic.resources.slice(currentResourceIndex, endOfGroupIndex);
		},
		nextResourceID() {
			if (!this.currentResourceGroup.length) return '';

			const firstGroupResourceIndex = this.currentLessonTopic.resources.findIndex(r => r.resourceID === this.currentResourceGroup[0].resourceID);

			const lastResourceOfGroup = this.currentResourceGroup[this.currentResourceGroup.length - 1];

			const lastResourceGroupIndex = this.currentLessonTopic.resources.findIndex((r, index) => r.resourceID === lastResourceOfGroup.resourceID && index >= firstGroupResourceIndex);

			const nextRe = this.currentLessonTopic.resources[lastResourceGroupIndex + 1];
			return nextRe?.resourceID ?? '';
		},
		lessons() {
			return this.classroomUserProgress?.syllabus?.lessons ?? null;
		},
		previousTopicID() {
			if (!this.lesson.topics) { return ''; }

			let indexID = 0;
			if (this.routeTopicID) {
				indexID = this.lesson.topics.findIndex(t => t.topicID === this.routeTopicID);
			}
			const previousIndexID = indexID - 1;

			if (indexID === -1) {
				return '';
			} else {
				return this.lesson.topics[previousIndexID].topicID;
			}
		},
		nextTopicID() {
			if (!this.lesson.topics) { return ''; }

			let indexID = 0;
			if (this.routeTopicID) {
				indexID = this.lesson.topics.findIndex(t => t.topicID === this.routeTopicID);
			}
			const nextIndexID = indexID + 1;

			if ((indexID === -1) || (nextIndexID >= this.lesson.topics.length)) {
				return '';
			} else {
				return this.lesson.topics[nextIndexID].topicID;
			}
		},
		nextLessonID() {
			if (!this.lessons) { return ''; }

			let indexID = 0;
			if (this.routeLessonID) {
				indexID = this.lessons.findIndex(l => l.lessonID === this.routeLessonID);
			}
			const nextIndexID = indexID + 1;

			if ((indexID === -1) || (nextIndexID >= this.lessons.length)) {
				return '';
			} else {
				return this.lessons[nextIndexID].lessonID;
			}
		},
		quizzesCompleted() {
			if (!this.classroomUserProgress || !this.classroomUserProgress.lessons) { return []; }
			const currentLessonProgress = this.classroomUserProgress.lessons.find(o => o.lessonID === this.routeLessonID);

			// If we don't find the current lesson return an empty array.
			if (!currentLessonProgress) { return []; }

			// Otherwise return a list of topics completed.
			return currentLessonProgress.progressItems.filter(item => item.type === 'QUIZ');
		},
		resourcesComplete() {
			return !!this.classroomUserProgress.lessons.find((lp) => lp.type === 'RESOURCES' && lp.itemProgressed === this.currentLessonTopic.topicID);
		},
		syllabus() {
			return this.store.getters.selectedSyllabus;
		},
		topicsCompleted() {
			if (!this.classroomUserProgress || !this.classroomUserProgress.lessons) { return []; }
			const currentLessonProgress = this.classroomUserProgress.lessons.find(o => o.lessonID === this.routeLessonID);

			// If we don't find the current lesson return an empty array.
			if (!currentLessonProgress) { return []; }

			// Otherwise return a list of topics completed.
			return currentLessonProgress.progressItems.filter(item => item.type === 'TOPIC');
		},
		resourcesCompleted() {
			if (!this.classroomUserProgress || !this.classroomUserProgress.lessons) { return []; }
			const currentLessonProgress = this.classroomUserProgress.lessons.find(o => o.lessonID === this.routeLessonID);

			if (!currentLessonProgress) { return []; }

			return currentLessonProgress.progressItems.filter(item => item.type === 'RESOURCE');
		}
	},
	data() {
		return {
			activePage: true,
			lessonProgressBar: false,
			loading: false
		}
	},
	methods: {
		async nextBtn() {
			this.loading = true;
			let response;
			if (this.nextResourceID) {
				const breakResource = this.currentResourceGroup.find((resource) => resource.content.type === 'BREAK');
				if (breakResource) {
					response = await this.$store.dispatch('submitClassroomProgress', [{
						type: 'RESOURCE',
						lessonID: this.routeLessonID,
						itemProgressed: breakResource.resourceID
					}]);
				}
			} else {
				response = await this.$store.dispatch('submitClassroomProgress', [{
					type: 'RESOURCES',
					lessonID: this.routeLessonID,
					itemProgressed: this.currentLessonTopic.topicID
				}]);
				const lessonAndTopicName = `${this.lesson.name} / ${this.currentLessonTopic.name}`
				this.logData('Advanced to next topic', lessonAndTopicName)
			}
			if (response instanceof DailyLimitMetError) {
				this.ionRouter.navigate(`/lessons`, 'forward', 'replace');
				return;
			}
			this.ionRouter.navigate(this.continueData.url, 'forward', 'replace');
		},
		previousBtn() {
			// if multiple resources, go to previous resource
			if (this.previousResourceID) {
				this.ionRouter.navigate(`/lessons/${this.routeLessonID}/${this.routeTopicID}/${this.previousResourceID}`, 'back', 'replace');
				return;
			}

			const lessonAndTopicName = `${this.lesson.name} / ${this.currentLessonTopic.name}`
			let path = `/lessons/${this.routeLessonID}/${this.previousTopicID}`;

			const previousTopic = this.lesson.topics.find(t => t.topicID === this.previousTopicID);
			if (previousTopic) {
				const lastResourceIndex = previousTopic.resources.length - 1;
				const startResourceIndex = previousTopic.resources.findLastIndex((r, index) => {
					return r.content.type === 'BREAK' && index < lastResourceIndex;
				});
				if (startResourceIndex !== -1) {
					const resourceAfterBreakIndex = startResourceIndex + 1;
					const startResource = previousTopic.resources[resourceAfterBreakIndex];
					path += `/${startResource.resourceID}`;
				}
			}

			// if single resource, go to previous topic
			this.logData('Went to previous topic', lessonAndTopicName)
			this.ionRouter.navigate(path, 'back', 'replace');
		},
		logData(event, details){
			api.logMobileActivity({
				institution: this.store.getters.institutionSettings.slug,
				event: event,
				affectedUserID: this.store.getters.user.userID,
				additionalData:{
					email: this.store.getters.user.email,
					activeUser: this.store.getters.selectedUser,
					currentAppVersions: this.store.getters.featureFlags.appVersions,
					mobileAppVersion: envConfig.version,
					tags: ['Action'],
					status: 'Success',
					details: details
				}
			});
		}
	},
	setup() {
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		const ionRouter = useIonRouter();

		const routeLessonID = (route.params.lessonID) ? route.params.lessonID : '';
		const routeTopicID = (route.params.topicID) ? route.params.topicID : '';
		const routeResourceID = (route.params.resourceID) ? route.params.resourceID : '';

		return {
			arrowBack,
			arrowForward,
			caretDownOutline,
			clipboard,
			informationCircle,
			ionRouter,
			routeLessonID,
			routeTopicID,
			routeResourceID,
			store,
			router,
			trophy
		};
	},
	async ionViewDidEnter() {
		// Mark all non-Video resources as complete upon viewing
		// If in dev mode, also mark videos as complete
		// Otherwise, video resources will mark themselves as complete when finished
		const resourcesToSubmit = this.currentResourceGroup.filter((resource) => {
			if (resource.content.type === 'BREAK') return false;
			if (this.store.getters.isDevMode) return true;
			if (resource.content.type !== 'VIDEO') return true;

			return false;
		}).map((resource) => ({
			type: 'RESOURCE',
			lessonID: this.routeLessonID,
			itemProgressed: resource.resourceID
		}));

		if (!resourcesToSubmit.length) return;
		const response = await this.$store.dispatch('submitClassroomProgress', resourcesToSubmit);
		if (response instanceof DailyLimitMetError) {
			this.ionRouter.navigate(`/lessons`, 'forward', 'replace');
		}
	}
});
</script>

<style lang="scss" scoped>
.lesson-nav-buttons {
	display: flex;
	justify-content: space-between;
	padding: 0 15px;

	&--align-right {
		justify-content: right;
	}
}
</style>
