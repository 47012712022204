<template>
	<ion-page>
		<ion-loading v-if="loading" show-backdrop />
		<ion-header>
			<ion-toolbar>
				<ion-buttons slot="start">
					<ion-button v-if="store.state.showSettingsData?.type !== 'userDetails'" @click="goBack()">
						<ion-icon color="light" :md="arrowBack" :ios="arrowBack"></ion-icon>
					</ion-button>
				</ion-buttons>
				<ion-title color="light">Permit Details</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="hideSettingsModal()">
						<ion-icon color="light" :md="close" :ios="close"></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<ion-modal
				class="date-modal"
				:is-open="showPermitIssueDateModal"
				@didDismiss="showPermitIssueDateModal = false"
			>
				<ion-content force-overscroll="false">
					<ion-datetime
						presentation="date"
						show-default-buttons
						:max="currentISODate"
						@click.stop
						@ionChange="updatePermitIssueDate"
					/>
				</ion-content>
			</ion-modal>
			<form>
				<ion-list>
					<ion-item>
						<ion-label position="stacked">State permit is issued in</ion-label>
						<ion-select disabled :value="user.permitIssuingState" placeholder="Select the state your permit is being issued in" @ionChange="selectState">
							<ion-select-option v-for="state in stateList" :key="state.abbreviation" :value="state.abbreviation">{{ state.name }}</ion-select-option>
						</ion-select>
					</ion-item>
					<ion-item class="error" :disabled="canEditPermitNumber">
						<ion-icon v-if="isPermitNumberValid" color="success" :ios="checkmarkCircle" :md="checkmarkCircle" slot="start" style="margin-top: 25px;"></ion-icon>
						<ion-icon v-else color="warning" :ios="alertCircle" :md="alertCircle" slot="start" style="margin-top: 25px;"></ion-icon>
						<ion-label position="stacked">Permit Number</ion-label>
						<ion-note style="margin-top: 7px;">{{permitExample}}</ion-note>
						<ion-input
							data-private
							@ionChange="enterPermitNumber"
							required="true"
							type="text"
							:value="user.permitNumber"
							v-maska="permitMask"
						/>
					</ion-item>
					<ion-item
						ref="permitIssued"
						:class="[!validPermitIssueDate ? 'permit-form-item--invalid' : 'permit-form-item--valid', 'form-item']"
						button
						@click="selectingPermitIssueDate"
						detail="false"
					>
						<ion-label position="stacked">Permit Issue Date</ion-label>
						<ion-input
							placeholder="MM/DD/YYYY"
							readonly
							required="true"
							type="string"
							:value="frontEndPermitIssueDate"
							@ionChange="validate($event)"
						/>
						<ion-note slot="error">{{ validPermitIssueDate ? '' : 'Invalid Permit Issue Date' }}</ion-note>
					</ion-item>
				</ion-list>

				<ion-list>
					<ion-item>
						<ion-label>Glasses / Contact Lenses Required</ion-label>
						<ion-checkbox
							color="success"
							:modelValue="user.correctiveLensesRequired"
							@update:modelValue="user.correctiveLensesRequired = $event"
						/>
					</ion-item>
				</ion-list>

				<p class="ion-padding ion-text-right">
					<ion-button
						color="success"
						:disabled="!isPermitNumberValid || !user.permitIssued || !validPermitIssueDate || !isConnectedToInternet"
						@click="saveSettings()"
					>Save Settings</ion-button>
				</p>
			</form>
		</ion-content>
	</ion-page>
</template>

<script>
import { api, UPDATE_USER_QUERY } from '@/core/api/api';
import { defineComponent } from "vue";
import {
	arrowBack,
	alertCircle,
	checkmarkCircle,
	close,
	sunny
} from 'ionicons/icons';
import { toast } from '@/core/toast/Toast';
import { useMutation } from '@vue/apollo-composable';
import {
	IonContent,
	IonHeader,
	IonIcon,
	IonTitle,
	IonToolbar,
	IonLoading
} from '@ionic/vue';
import { useStore } from 'vuex';
import { maska } from 'maska'
import envConfig from "@/core/config/env.config";
import { addYears, addMonths, parseISO, format } from 'date-fns';

export default defineComponent({
	name: "SettingsUserDetails",
	components: {
		IonContent,
		IonHeader,
		IonIcon,
		IonTitle,
		IonToolbar,
		IonLoading
	},
	computed: {
		canEditPermitNumber() {
			return (this.user.permitIssuingState) ? false : true;
		},
		frontEndPermitIssueDate() {
			if(!this.user.permitIssued) return '';
			return format(parseISO(this.user.permitIssued), 'M/d/yyyy');
		},
		isConnectedToInternet() {
			return this.store?.getters?.connectionStatus?.connected;
		},
		permitExample() {
			return (this.user.permitIssuingState) ? this.statePermitNumberRegexLibrary[this.user.permitIssuingState].example : '';
		},
		permitPattern() {
			return (this.user.permitIssuingState) ? this.statePermitNumberRegexLibrary[this.user.permitIssuingState].regex : '';
		},
		permitMask() {
			return (this.user.permitIssuingState) ? this.statePermitNumberRegexLibrary[this.user.permitIssuingState].mask : '';
		},
		stateList() {
			return this.$store.state?.stateList || [];
		},
		currentISODate() {
			return new Date().toISOString();
		},
		validPermitIssueDate() {
			const birthDate = parseISO(this.user.birthDate);
			const permitIssued = parseISO(this.user.permitIssued)

			if (isNaN(permitIssued) || isNaN(birthDate)) return true;

			const { years = 15, months = 0 } = this.store.getters.stateSettings.find(({state}) => state === this.user.permitIssuingState)?.schedulerSettings?.permitEligibility || {};
			const minimumDate = addMonths(addYears(birthDate, years), months);

			return permitIssued >= minimumDate;
		},
	},
	directives: { maska },
	data() {
		return {
			isPermitNumberValid: false,
			showPermitIssueDateModal: false,
			statePermitNumberRegexLibrary: {
				//Alabama
				AL: {
					example: 'EX: 12345678',
					regex: /^\d{1,8}$/,
					mask: { mask: '########' }
				},
				//Alaska
				AK: {
					example: 'EX: 1234567',
					regex: /^\d{1,7}$/,
					mask: { mask: '#######' }
				},
				//Arizona
				AZ: {
					example: 'EX: A12345678',
					regex: /^[0-9A-Z]\d{8}$/,
					mask: { mask: 'Z########', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//Arkansas
				AR: {
					example: 'EX: 123456789',
					regex: /^\d{4,9}$/,
					mask: { mask: '#########' }
				},
				//California
				CA: {
					example: 'EX: A1234567',
					regex: /^[A-Z]\d{7}$/,
					mask: { mask: 'A#######' }
				},
				//Colorado
				CO: {
					example: 'EX: 12-345-6789',
					regex: /^(?:\d[- ]*){9}|^[A-Z](?:\d[- ]*){3,6}$|^[A-Z]{2}-(?:\d[- ]*){2,5}$/,
					mask: { mask: 'ZZ-###-####', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }} }
				},
				//Connecticut
				CT: {
					example: 'EX: 123456789',
					regex: /^\d{9}$/,
					mask: { mask: '#########' }
				},
				//Delaware
				DE: {
					example: 'EX: 1234567',
					regex: /^\d{1,7}$/,
					mask: { mask: '#######' }
				},
				//District Of Columbia
				DC: {
					example: 'EX: 123456789',
					regex: /^\d{7}$|^\d{9}$/,
					mask: { mask: '#########' }
				},
				//Florida
				FL: {
					example: 'EX: A123-456-78-901-2',
					regex: /^[A-Z](?:\d[- ]*){12}$/,
					mask: { mask: 'A###-###-##-###-#' }
				},
				//Georgia
				GA: {
					example: 'EX: 1234567',
					regex: /^\d{7,9}$/,
					mask: { mask: '#########' }
				},
				//Hawaii
				HI: {
					example: 'EX: A12345678',
					regex: /^[A-Z]?\d{8}$|^\d{9}$/,
					mask: { mask: 'Z########', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }} }
				},
				//Idaho
				ID: {
					example: 'EX: AA123456A',
					regex: /^[A-Z]{2}\d{6}[A-Z]$|^\d{9}$/,
					mask: { mask: 'ZZ######Z', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }} }
				},
				//Illinois
				IL: {
					example: 'EX: A123-4567-8901',
					regex: /^[A-Z](?:\d[- ]*){11,12}$/,
					mask: { mask: 'A###-####-#####' }
				},
				//Indiana
				IN: {
					example: 'EX: A123-45-6789',
					regex: /^[A-Z](?:\d[- ]*){9}$|\d(?:\d[- ]*){9,10}$/,
					mask: { mask: 'Z###-##-####', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//Iowa
				IA: {
					example: 'EX: 123AB4567',
					regex: /^\d{9}$|^\d{3}[A-Z]{2}\d{4}$/,
					mask: { mask: '###ZZ####', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				// TODO - Add second mask after update to maska V3
				//current version of maska does not work with an array of masks.
				//Once version is updated try adding missing mask A#A#A#
				//Kansas
				KS: {
					example: 'EX: A12-34-5678',
					regex: /^[A-Z]\d[A-Z]-\d[A-Z]-\d$|[0-9A-Z](?:\d[- ]*){8}$/,
					mask: { mask: 'Z#Z-#Z-####', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//Kentucky
				KY: {
					example: 'EX: A12-345-678',
					regex: /^[A-Z](?:\d[- ]*){8,9}$|^(?:\d[- ]*){9}$/,
					mask: { mask: 'Z##-###-####', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//Louisiana
				LA: {
					example: 'EX: 123456789',
					regex: /^\d{1,9}$/,
					mask: { mask: '#########' }
				},
				//Maine
				ME: {
					example: 'EX: 1234567A',
					regex: /^\d{7,8}$|^\d{7}[A-Z]$/,
					mask: { mask: '#######Z', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//Maryland
				MD: {
					example: 'EX: A-123-456-789-012',
					regex: /^[A-Z]-(?:\d[- ]*){12}$/,
					mask: { mask: 'A-###-###-###-###' }
				},
				//Massachusetts
				MA: {
					example: 'EX: A12345678',
					regex: /^[A-Z]\d{8}$|^\d{9}$/,
					mask: { mask: 'Z########', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//Michigan
				MI: {
					example: 'EX: A 123 456 789 0',
					regex: /^[A-Z]\s(?:\d[- ]*){10}$|^[A-Z]\s(?:\d[- ]*){12}$/,
					mask: { mask: 'A ### ### ### ###' }
				},
				//Minnesota
				MN: {
					example: 'EX: A123456789012',
					regex: /^[A-Z]\d{12}$/,
					mask: { mask: 'A############' }
				},
				//Mississippi
				MS: {
					example: 'EX: 123-45-6789',
					regex: /^(?:\d[- ]*){9}$/,
					mask: { mask: '###-##-####' }
				},
				//Missouri
				MO: {
					example: 'EX: 123A123456',
					regex: /^\d{3}[A-Z]\d{6}$|^[A-Z]\d{5,9}$|^[A-Z]\d{6}R$|^\d{8}[A-Z]{2}$|^\d{9}[A-Z]?$/,
					mask: { mask: 'Z##Z###ZZZ', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//Montana
				MT: {
					example: 'EX: A123456789',
					regex: /^[A-Z]\d{8}$|^\d{9}$|^\d{13,14}$/,
					mask: { mask: 'Z#############', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//Nebraska
				NE: {
					example: 'EX: A12345678',
					regex: /^[A-Z]\d{6,8}$/,
					mask: { mask: 'A########' }
				},
				//Nevada
				NV: {
					example: 'EX: 123456789',
					regex: /^\d{9,10}$|^\d{12}$|^X\d{8}$/,
					mask: { mask: 'Z###########', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//New Hampshire
				NH: {
					example: 'EX: 12ABC12345',
					regex: /^\d{2}[A-Z]{3}\d{5}$/,
					mask: { mask: '##AAA#####' }
				},
				//New Jersey
				NJ: {
					example: 'EX: A1234-56789-01234',
					regex: /^[A-Z](?:\d[- ]*){14}$/,
					mask: { mask: 'A####-#####-#####' }
				},
				//New Mexico
				NM: {
					example: 'EX: 12345678',
					regex: /^\d{8,9}$/,
					mask: { mask: '#########' }
				},
				// TODO - Add second mask after update to maska V3
				//current version of maska does not work with an array of masks.
				//Once version is updated try adding missing mask AAAAAAAA
				//New York
				NY: {
					example: 'EX: A12 345 678 901 234 567 8',
					regex: /^[A-Z](?:\d[- ]*){7}$|^[A-Z](?:\d[- ]*){18}$|^(?:\d[- ]*){8,9}$|^(?:\d[- ]*){15}$/,
					mask: { mask: 'Z## ### ### ### ### ### #', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//North Carolina
				NC: {
					example: 'EX: 123456789012',
					regex: /^\d{1,12}$/,
					mask: { mask: '############' }
				},
				//North Dakota
				ND: {
					example: 'EX: ABC-12-3456',
					regex: /^[A-Z]{3}-(?:\d[- ]*){6}$|^(?:\d[- ]*){9}$/,
					mask: { mask: 'ZZZ-##-####', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//Ohio
				OH: {
					example: 'EX: A1234567',
					regex: /^[A-Z]\d{4,8}$|^[A-Z]{2}\d{3,7}$|^\d{8}$/,
					mask: { mask: 'ZZ#######', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//Oklahoma
				OK: {
					example: 'EX: A123456789',
					regex: /^[A-Z]\d{9}$|^\d{9}$/,
					mask: { mask: 'Z#########', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//Oregon
				OR: {
					example: 'EX: 123456789',
					regex: /^\d{1,9}$/,
					mask: { mask: '#########' }
				},
				//Pennsylvania
				PA: {
					example: 'EX: 12 345 678',
					regex: /^(?:\d[- ]*){8}$/,
					mask: { mask: '## ### ###' }
				},
				//Rhode Island
				RI: {
					example: 'EX: 1234567',
					regex: /^\d{7}$|^[A-Z]\d{6}$/,
					mask: { mask: 'Z######', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//South Carolina
				SC: {
					example: 'EX: 123456789012',
					regex: /^\d{5,11}$/,
					mask: { mask: '###########' }
				},
				//South Dakota
				SD: {
					example: 'EX: 123456789012',
					regex: /^\d{6,10}$|\d{12}$/,
					mask: { mask: '############' }
				},
				//Tennessee
				TN: {
					example: 'EX: 123456789',
					regex: /^\d{7,9}$/,
					mask: { mask: '#########' }
				},
				//Texas
				TX: {
					example: 'EX: 12345678',
					regex: /^\d{7,8}$/,
					mask: { mask: '########' }
				},
				//Utah
				UT: {
					example: 'EX: 1234567890',
					regex: /^\d{4,10}$/,
					mask: { mask: '##########' }
				},
				//Vermont
				VT: {
					example: 'EX: 12345678',
					regex: /^\d{8}$|^\d{7}A$/,
					mask: { mask: '#######Z', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				// TODO - Add second mask after update to maska V3
				//current version of maska does not work with an array of masks.
				//Once version is updated try adding missing mask #########
				//Virginia
				VA: {
					example: 'EX: A12-34-5678',
					regex: /^[A-Z](?:\d[- ]*){8,11}$|^(?:\d[- ]*){9}$/,
					mask: { mask: 'Z##-##-#######', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//Washington
				WA: {
					example: 'EX: AAAAAAA1234',
					regex: /^[A-Za-z]{1,7}[A-Z0-9]{5}$/,
					mask: { mask: 'AAAAAAAZZZZZ', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//West Virginia
				WV: {
					example: 'EX: 1234567',
					regex: /^\d{7}$|^[A-Z]{1,2}\d{5,6}$/,
					mask: { mask: 'ZZ######', tokens: { 'Z': { pattern: /[0-9A-Z]/, uppercase: true }}}
				},
				//Wisconsin !!!
				WI: {
					example: 'EX: A123-4567-8901-23',
					regex: /^[A-Z](?:\d[- ]*){13}$/,
					mask: { mask: 'A###-####-####-##' }
				},
				//Wyoming
				WY: {
					example: 'EX: 123456-789',
					regex: /^(?:\d[- ]*){9,10}$/,
					mask: { mask: '######-####' }
				}
			},
			user: {
				correctiveLensesRequired: '',
				permitIssued: '',
				permitIssuingState: '',
				permitNumber: '',
				userID: ''
			},
			loading: false
		}
	},
	methods: {
		enterPermitNumber(event) {
			this.user.permitNumber = event.detail.value;
			this.isPermitNumberValid = this.user.permitNumber.match(this.permitPattern);
		},
		async saveSettings() {
			this.updateUser({
				userID: this.user.userID,
				selectedUserFields: {
					permitNumber: this.user.permitNumber,
					permitIssued: this.user.permitIssued,
					correctiveLensesRequired: this.user.correctiveLensesRequired,
					permitIssuingState: this.user.permitIssuingState
				}
			});

			if(this.store.state.showSettingsData?.type === 'userDetails') {
				this.hideSettingsModal();
			} else {
				this.settingsIonNav.pop();
			}
		},
		selectingPermitIssueDate() {
			this.showPermitIssueDateModal = true;
		},
		selectState({ detail }) {
			this.user.permitIssuingState = detail.value;
		},
		hideSettingsModal() {
			this.store.commit('hideSettingsModal');
		},
		updatePermitIssueDate({ detail }) {
			this.user.permitIssued = format(new Date(detail.value), 'yyyy-MM-dd');
		},
		validate(ev) {
			this.$refs.permitIssued.$el.classList.remove('ion-valid');
			this.$refs.permitIssued.$el.classList.remove('ion-invalid');


			if (!this.validPermitIssueDate) {
				this.$refs.permitIssued.$el.classList.add('ion-invalid');
				return false;
			} else {
				this.$refs.permitIssued.$el.classList.add('ion-valid');
				return true;
			}
		},
	},
	async mounted() {
		try {
			this.loading = true;
			if(this.store.state.showSettingsData?.type === 'userDetails') {
				this.user = await api.getUserDetails(this.store.state.showSettingsData.user);
			} else {
				this.user = await api.getUserDetails(this.$attrs.userID);
			}
			this.user.permitIssuingState = this.store.getters?.institutionSettings?.stateSetting?.state || '';
			this.setLogEvent(this.user.permitNumber ? 'Update Permit Details' : 'Added Permit Details');
			this.loading = false;
		} catch (e) {
			this.loading = false;
			console.error(e);
		}
	},
	props: [
		"modalNav"
	],
	setup(props) {
		const store = useStore();
		const settingsIonNav = document.querySelector('ion-nav');
		let addingOrUpdatingPermitNumber = '';

		const logData = (status) => {
			const logData = {
				institution: store.getters.institutionSettings.slug,
				event: addingOrUpdatingPermitNumber,
				affectedUserID: store.getters.user.userID,
				additionalData:{
					email: store.getters.user.email,
					activeUser: store.getters.selectedUser,
					currentAppVersions: store.getters.featureFlags.appVersions,
					mobileAppVersion: envConfig.version,
					tags: ['Action'],
					status: status
				}
			};
			api.logMobileActivity(logData);
		}

		const setLogEvent = (event) => {
			addingOrUpdatingPermitNumber = event;
		}

		const {
			mutate: updateUser,
			onDone: updateUserOnDone,
			onError: updateUserOnError
		} = useMutation( UPDATE_USER_QUERY );

		updateUserOnDone(async () => {
			logData('Success');
			toast.success({
				message: 'User successfully updated.',
				duration: 3000
			});
			await store.dispatch('getStudents', store.state.auth.user.userID);
		});

		updateUserOnError(error => {
			logData('Failure');
			toast.error({
				message: 'Unable to update user details at this time. Please try again later.',
				duration: 3000
			});
		});

		const goBack = () => {
			const nav = props.modalNav.value;
			nav.pop();
		}

		return {
			alertCircle,
			arrowBack,
			checkmarkCircle,
			close,
			goBack,
			store,
			sunny,
			updateUser,
			settingsIonNav,
			setLogEvent
		};
	}
});
</script>

<style scoped lang="scss">

.date-modal {
	background-color: rgba(0, 0, 0, 0.3);
	--border-radius: 8px;
	--height: 80%;
	--width: 80%;
	--max-width: 350px;

	ion-datetime {
		height: 100%;
		max-width: 350px;
		width: auto;
	}
}

ion-content,
ion-item,
ion-item ion-note,
ion-header {
	ion-toolbar {
		background-color: var(--ion-color-primary);
		border: 0;
	}
}

ion-select::part(text) {
	color: var(--ion-color-primary);
}

ion-select::part(icon) {
	color: var(--ion-color-primary);
	opacity: 1;
}

.permit-form-item--invalid {
	--background: transparent;
	--border-color: var(--ion-color-danger);
	--border-width: 1px;
	--color: var(--ion-color-primary);
}

.form-item {
	min-height: 70px;
}
</style>